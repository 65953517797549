import React from 'react'
import styled from 'styled-components'
import { TextField } from '@material-ui/core'

const SearchBar = ({ onSearch, label }: { onSearch: (val?: string) => void, label?: string }) => {
	const [search, setSearch] = React.useState('')
	return (
		<SearchForm
			onSubmit={e => {
				e.preventDefault()
				onSearch(search || undefined)
			}}
		>
			<TextField
				variant="outlined"
				value={search}
				onChange={e => {
					if (!!search && !e.target.value) {
						onSearch(undefined)
					}
					setSearch(e.target.value)
				}}
				label={label || "Search"}
			/>
		</SearchForm>
	)
}

export default SearchBar

const SearchForm = styled.form`
	background-color: white;
	margin: 12px;
	display: flex;
	align-self: flex-end;
	padding: 4px 8px;
	border-radius: 4px;
`
