import React from 'react'
import styled from 'styled-components'
import { TablePagination } from '@material-ui/core'

const Pagination = ({count, page, setPage}: {count?: number, page?: number, setPage?: (page: number) => void}) => (
  <PagCont>
    <TablePagination
      component="div"
      count={count || 0}
      rowsPerPageOptions={[20]}
      rowsPerPage={20}
      page={page || 0}
      backIconButtonProps={{
        'aria-label': 'previous page',
      }}
      nextIconButtonProps={{
        'aria-label': 'next page',
      }}
      onChangePage={(_: unknown, newPage: number) => setPage && setPage(newPage)}
    />
  </PagCont>
)

export default React.memo(Pagination)

const PagCont = styled.div`
  background-color: white;
  padding: 6px;
  border-radius: 4px;
  margin-top: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,.3);
  align-self: flex-end;
`
