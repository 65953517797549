import {Button, Tab, Tabs, Typography} from '@material-ui/core'
import React, { useState } from 'react'
import styled from 'styled-components'
import {Property, PropertyStatus, useAdminGetPropertyQuery} from '../../../apollo/generated'
import Loading from '../../ui/Loading'

import Canvas from './Canvas'
import ConvertToFairmarketHomeButton from '../../ConvertToFairmarketHomeButton'

interface Props {
	property?: Property
}

const AdminPropertyView = ({ property }: Props) => {
  const [value, setValue] = useState(0);
  const {data, loading, refetch} = useAdminGetPropertyQuery({
		variables: { id: property.id! }
	})
	if (!data || loading) {
		return <Loading />
	}

  const {houseNumber, streetName, city, state, zipCode, renoworksDisplayId, ready} = data.adminGetProperty;

	return (
		<Outer>
			<>
				<ButtonRow>
					<Tabs value={value} onChange={(e: any, newVal: number) => setValue(newVal)}>
						<Tab label="3D Viewer" />
						<Tab label="Design Center" />
					</Tabs>
					<div>
						<JobTitle>
							{houseNumber} {streetName}
						</JobTitle>
						<JobDescr>
							{city}, {state} {zipCode}
						</JobDescr>
					</div>
					<JobDates>{`Created: ${new Date(data.adminGetProperty.createdAt).toLocaleDateString()}`}</JobDates>
				</ButtonRow>

				<Inner>
					{value === 0 && <Canvas property={ data.adminGetProperty as Property} />}
					{value === 1 && <iframe
						style={{ width: "100%", height: "100%", marginBottom: 0 }}
						src={`https://fairmarket.renoworks.com/pages/project?project=${renoworksDisplayId}`}
					/>}

				</Inner>
				<ConvertToFairmarketHomeButton property={property} />
			</>
		</Outer>
	)
}

const Inner = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: stretch;
	width: 100%;
		max-height: 80vh;
		height: 500px;
	margin-bottom: 8px;

	> * {
		max-height: 80vh;
		height: 500px;
		overflow-y: auto;
		overflow-x: hidden;
		position: relative;
		min-width: 200px;
		flex: 1;
		border: 1px solid rgba(0, 0, 0, 0.3);
		border-radius: 4px;
		margin: 8px;
		padding: 16px;
	}
`

const JobTitle = styled(Typography)`
	&& {
		font-size: 16px;
		letter-spacing: 0.15px;
		font-weight: 500;
	}
`

const JobDescr = styled(Typography)`
	&& {
		font-size: 14px;
		opacity: 0.85;
		text-overflow: ellipsis;
	}
`

const JobDates = styled(Typography)`
	&& {
		font-size: 13px;
		opacity: 0.75;
		text-overflow: ellipsis;
	}
`

const Outer = styled.div`
	display: flex;
	flex-direction: column;
	max-height: 95%;
`

const ButtonRow = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	padding: 0 12px;

	@media (min-width: 850px) {
		> *:last-child {
			margin-left: 8px;
		}

		> *:first-child {
			margin-right: auto;
		}
	}

	@media (max-width: 849px) {
		flex-direction: column;
		align-items: stretch;
		margin-top: 12px;
		margin-bottom: 12px;
		> * {
			margin: 4px;
		}
	}
`

export default React.memo(AdminPropertyView)
