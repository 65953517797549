import React from 'react'
import styled from 'styled-components'
import {
	Paper,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Button,
	Select,
} from '@material-ui/core'
import {
	useAdminGetUsersQuery,
	Property,
	PropertyStatus
} from '../../apollo/generated'
import Loading from '../ui/Loading'
import SearchBar from '../ui/SearchBar'
import ResponsiveModal from '../ui/ResponsiveModal'
import AdminPropertyView from '../modals/AdminPropertyView'
import NewHomeModal from '../modals/newHomeModal'
import Pagination from '../ui/Pagination'

const PropertyDropdown = ({
	properties,
	refetch,
	userId
}: {
	properties: Partial<Property>[],
	refetch: () => void
	userId: string
}) => {
	const [selectedId, setSelectedId] = React.useState('')

  const onNewHomeModalClose = success => {
    if (success) refetch()
    setSelectedId("");
  }

	return (
		<>
			<Select
				native
				value={selectedId}
				onChange={e => setSelectedId(e.target.value)}
			>
				<option value="">{properties.length}</option>
				{properties.map(p => (
					<option value={p.id!} key={p.id!}>
						{p.houseNumber} {p.streetName}, {p.city}, {p.state} {p.zipCode}
					</option>
				))}
				<option value="add">+ Add Property</option>
			</Select>
			<ResponsiveModal open={!!selectedId && selectedId !== 'add'} onClose={() => setSelectedId('')}>
        {!!selectedId && selectedId !== 'add' && (
          <AdminPropertyView
            property={properties.find(p => p.id === selectedId)}
            onClose={() => setSelectedId('')}
          />
        )}
			</ResponsiveModal>
			<ResponsiveModal open={selectedId === 'add'} onClose={() => setSelectedId('')} overflow={'inherit'}>
        {selectedId === 'add' && (
          <NewHomeModal
            onClose={onNewHomeModalClose}
					isAdmin
					intendedUserId={userId}
          />
        )}
			</ResponsiveModal>
		</>
	)
}

const AdminUsers = () => {
	const [search, setSearch] = React.useState<string | undefined>(undefined)
	const [page, setPage] = React.useState(0)
	const { data, loading, refetch } = useAdminGetUsersQuery({
		variables: { page, search: search || undefined }
  });

	return (
		<Container>
			<SearchBar onSearch={setSearch} />
			<Paper style={{ margin: 20, padding: 12 }}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>Info</TableCell>
							<TableCell>Type</TableCell>
							<TableCell>Created</TableCell>
							<TableCell align="right">Biddable</TableCell>
							<TableCell align="right">Ongoing</TableCell>
							<TableCell align="right">Properties</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{(loading || !data || !data.adminGetUsers) ? <Loading /> : data.adminGetUsers.map(row => (
							<TableRow key={row.id}>
								<TableCell component="th" scope="row">
									{row.fullName}
									<br />
									{row.email}
								</TableCell>
								<TableCell>{row.role}</TableCell>
								<TableCell>
									{new Date(row.createdAt).toLocaleDateString()}
								</TableCell>
								<TableCell align="right">{row.biddableJobCount}</TableCell>
								<TableCell align="right">{row.ongoingJobCount}</TableCell>
								<TableCell align="right">
									<PropertyDropdown
										userId={row.id!}
										refetch={refetch}
                    properties={row.properties}
									/>
								</TableCell>
							</TableRow>
						))}
						<TableRow>
							<Button
								style={{ marginTop: 8 }}
								href="/admin/create-account"
							>
								New User
							</Button>
						</TableRow>
					</TableBody>
				</Table>
			</Paper>
			<Pagination count={(data && data.adminGetUsersCount) || 0} page={page} setPage={p => setPage(p)} />
		</Container>
	)
}

export default AdminUsers

const Container = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	align-items: stretch;
	overflow-y: auto;
`
