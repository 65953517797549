import React from 'react'
import { Typography } from '@material-ui/core'
import styled from 'styled-components'
import { useQuery } from '@apollo/react-hooks'

import {getExampleRenoworksId} from "../../../components/activeJobs/NoPropertiesYet";
import {Property, PropertyStatus} from '../../../apollo/generated'
import {PropertyMeasurements} from '../../../apollo/types'
import CanvasHouseViewer from '../../canvasHouseViewer/canvasHouseViewer'
import { GET_OPTIONS } from '../../jobCreator/jobCreator'
import MeasurementsDisplayer from '../../measurementsDisplayer'
import Loading from '../../ui/Loading'

interface Props {
	property?: Property
}

const AdminPropertyViewCanvas = ({ property }: Props) => {
	const { data, loading } = useQuery(GET_OPTIONS)
	if (!property) {
		return null
	}
	if (!data || loading) {
		return <Loading />
	}
	const selectedPropertySpecs: PropertyMeasurements = (data.selectedPropertySpecs &&
		JSON.parse(data.selectedPropertySpecs)) || { selections: [] }

	const {
		renoworksId,
		legacyHomeSpecId,
		propertySpec,
		ready,
		newViewer
	} = property;

	const canvasProps = {
		legacyHomeSpecId,
		renoworksId: ready === PropertyStatus.Ready ? renoworksId : getExampleRenoworksId(),
		propertySpec,
		newViewer,
	};

	return (
		<>
			<CanvasHouseViewer
				style={{
					minWidth: 300,
					height: 300,
					alignSelf: 'center',
					padding: 0,
					overflowY: 'hidden'
				}}
				{...canvasProps}
			/>
			<MeasCont>
				{(selectedPropertySpecs.selections || []).length === 0 ? (
					<HelperText style={{ alignSelf: 'center', textAlign: 'center' }}>
						Select home elements to view their measurements
					</HelperText>
				) : (
					<MeasurementsDisplayer
						compact
						propertyMeasurements={selectedPropertySpecs}
					/>
				)}
			</MeasCont>
		</>
	)
}

const MeasCont = styled.div`
	&& {
		flex-direction: column;
		align-items: stretch;
		justify-content: center;
	}
`

const HelperText = styled(Typography).attrs({ variant: 'subtitle1' })`
	&& {
		font-weight: bold;
	}
`

export default React.memo(AdminPropertyViewCanvas)
